const colors = [
	{ color: "#2AB24C", overlay: "#7FD194", dark: "#228e3d" },
	{ color: "#E91E63", overlay: "#F278A1", dark: "#ba184f" },
	{ color: "#FF5722", overlay: "#FF9A7A", dark: "#cc461b" },
	{ color: "#9C27B0", overlay: "#C47DD0", dark: "#7d1f8d" },
	{ color: "#FF1744", overlay: "#FF748F", dark: "#cc1236" },
	{ color: "#f6b93b", overlay: "#FAD589", dark: "#c5942f" },
	{ color: "#4687F4", overlay: "#90B7F8", dark: "#386cc3" },
	{ color: "#252525", overlay: "#5a5a5a", dark: "#141414" },
];

const greetings = [
	`It was a pleasure to serve you today.\nHave an amazing ${
		new Date().getHours() < 18 && new Date().getHours() > 3
			? "day"
			: "night"
	}!`,
	`Ca a été un plaisir de vous servir aujourd'hui. Excellente ${
		new Date().getHours() < 18 && new Date().getHours() > 3
			? "journée"
			: "soirée"
	}`,
	"Thank you for accepting my service. I hope to see you back again!",
	"C’était un plaisir de vous servir ce midi, passez une très belle journée",
];

const config = {
	colors,
	greetings,
};

export default config;
