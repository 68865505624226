import FirebaseWrapper from "./Wrapper";
import moment from "moment";

import config from "./config";

const DEBUG = true;

class Firebase extends FirebaseWrapper {
	/**
	 * Checks if user is authenticated
	 * If not, signs user in and returns uid
	 * @param {*} callback
	 */
	authUser(callback = () => {}) {
		this._getAuth().onAuthStateChanged((user) => {
			if (user) {
				const uid = user.uid;
				DEBUG && console.log("User authenticated", uid);
				this.currentUser = user;
				callback(uid);
			} else {
				this.signIn();
			}
		});
	}

	/**
	 * Finds the grantee profile by using user_code
	 * Returns grantee public profile
	 * @param {*} user_code
	 * @returns
	 */
	async loadGrantee(user_code) {
		if (!user_code) return null;

		// TODO: Move this to a repository pattern
		const granteeSnap = await this._getDb()
			.ref("grantee")
			.orderByChild("user_code")
			.equalTo(user_code)
			.once("value");

		const grantees = granteeSnap.val();

		if (!grantees) {
			DEBUG && console.log("Grantee not found", this.env, user_code);
			return null;
		}

		const grnt = grantees[Object.keys(grantees)[0]];
		var granteeKey = Object.keys(grantees)[0];

		if (!grnt || grnt.disabled) {
			DEBUG &&
				console.log("Grantee not found", this.env, user_code, grantees);
			return null;
		}

		return {
			...grnt.public_profile,
			location: grnt.country,
			uid: granteeKey,
		};
	}

	/**
	 * Get profile image signed url
	 * @param {*} profile_image
	 * @returns
	 */
	async dowloadProfileImage(profile_image) {
		if (!profile_image) return null;

		const storage = this._getStorage();
		const url = await storage.ref(profile_image).getDownloadURL();
		DEBUG && console.log("Download url retreived", url);
		return url;
	}

	/**
	 * Call firebase function to initialize a lemonway
	 * payment
	 * @param {*} Grantee
	 * @param {*} grant
	 * @returns
	 */
	async initPayment(Grantee, grant) {
		const data = {
			to: Grantee.uid,
			rate: true,
			tip: grant.tip || 0,
			fee: grant.fee || 0,
			currency: "EUR",
			userCode: Grantee.profile.userCode.substring(
				Grantee.profile.userCode.lastIndexOf("/") + 1,
				Grantee.profile.userCode.length
			),
			env: this.env,
		};

		DEBUG && console.log("Init payment", data);
		const fn = this._getFn();
		const result = await fn.httpsCallable(config.endpoints.init)(data);

		if (!result) {
			DEBUG && console.log("Function failed", result);
			return null;
		}

		DEBUG && console.log("RESULT", result.data);
		return result.data;
	}

	/**
	 * Init a sample Lemonway webkit to initialize Lemonway cache
	 */
	async initPreload(Grantee) {
		return "https://google.com";
	}

	async __initPreload(Grantee) {
		const data = {
			to: Grantee.uid,
			rate: true,
			tip: 1,
			fee: 0.2,
			currency: "EUR",
			userCode: Grantee.profile.userCode.substring(
				Grantee.profile.userCode.lastIndexOf("/") + 1,
				Grantee.profile.userCode.length
			),
			fake: true,
			env: this.env,
		};

		DEBUG && console.log("Init preload payment", data);
		const fn = this._getFn();
		const result = await fn.httpsCallable(config.endpoints.init)(data);

		if (!result) {
			DEBUG && console.log("Function failed", result);
			return null;
		}

		DEBUG && console.log("PRELOAD RESULT", result.data);
		return result.data;
	}

	/**
	 * Finilize a payment or a grant
	 * @param {*} grantKey
	 * @returns
	 */
	async validateGrant(grantKey) {
		const data = {
			tempGrant: grantKey,
			env: this.env,
		};
		const fn = this._getFn();
		await fn.httpsCallable(config.endpoints.validate)(data);
		DEBUG && console.log("Validated grant", grantKey);
		return true;
	}

	/**
	 * Create grant with no payment
	 * @param {*} Grantee
	 * @returns
	 */
	async recordNoTip(Grantee) {
		const grant = {
			rate: true,
			tip: 0,
			fee: 0,
			currency: "EUR",
			date_sent: moment().format("X"),
			date_accepted: moment().format("X"),
			from: this.currentUser.uid,
			to: Grantee.uid,
			new: true,
			from_web: true,
		};

		const db = this._getDb();
		var pendingRef = db.ref("pending_grants");
		var newPendingRef = pendingRef.push();
		var grantKey = newPendingRef.key;
		newPendingRef.set(grant);
		DEBUG && console.log("Created pending grant", grant, grantKey);

		return grantKey;
	}

	/**
	 * Load pending grant
	 * @param {*} grant_uid
	 * @returns
	 */
	async loadPendingGrant(grant_uid) {
		const db = this._getDb();
		const pg_snap = await db
			.ref("pending_grants/" + grant_uid)
			.once("value");
		if (!pg_snap) {
			DEBUG && console.log("Pending grant does not exist", grant_uid);
			return;
		}
		const pg = pg_snap.val();
		DEBUG && console.log("Retreived pending grant", pg);
		if (pg) {
			return { ...pg, uid: grant_uid };
		} else {
			return null;
		}
	}

	/**
	 * Track grantee scan by updating stats
	 * @param {*} grant_uid
	 * @returns
	 */
	async trackScan(grant_uid) {
		const database = this._getDb();
		let ref = "jar/" + grant_uid + "/" + moment().format("YYYYMMDD");
		const snap = await database.ref(ref).once("value");
		let stats = snap.val();

		if (!stats) {
			DEBUG && console.log("Creating scan stats");
			await database.ref(ref).set({
				scans: 1,
				grants_count: 0,
				total_amount: 0,
				last_scan: moment().format("X"),
				last_tip: null,
				grants_with_tip: 0,
				grants_without_tip: 0,
				grants: [],
			});
		} else {
			DEBUG && console.log("Updating scan stats");
			await database.ref(ref).update({
				scans: stats.scans + 1,
				last_scan: moment().format("X"),
			});
		}

		DEBUG && console.log("Scan track successful");

		return true;
	}
}

export default Firebase;
