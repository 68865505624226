import { useContext } from "react";

import AppContext from "./context";
import Grantor from "./grantor";

const GranteeScreen = ({
	setLoading,
	setFinished,
	grantReturned = null,
	grantSuccess = false,
}) => {
	const { Grantee } = useContext(AppContext);
	return (
		<>
			<div className={"grantee-wrapper"}>
				<div className={"grantee-header"}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							marginBottom: -10,
						}}
					>
						{Grantee.profile_image ? (
							<img
								src={Grantee.profile_image}
								className={"profile_image"}
								alt={"Grantee Profile"}
							/>
						) : (
							<div
								className={"profile_image center"}
								style={{
									background: Grantee.getBackground(),
								}}
							>
								{Grantee.getName()[0].toUpperCase()}
							</div>
						)}
						<h4 className={"grantee-name"}>{Grantee.getName()}</h4>
					</div>
					<p
						className={"grantee-message"}
						style={{
							background: Grantee.getMessage().color,
						}}
					>
						{Grantee.getMessage().message}
					</p>
				</div>
				<div style={{ flex: 1 }} />
				<div
					className={"bottom-sheet"}
					style={{ background: "#000000dd" }}
				>
					<div className={"grantee-navbar"}>
						<img
							className={"logo"}
							src={"/assets/img/grantlogo.png"}
							alt={"Thumbs up"}
						/>
						<h4 style={{ marginTop: -5 }}>&nbsp;ツ</h4>
					</div>
					<Grantor
						setLoading={setLoading}
						setFinished={setFinished}
						grantReturned={grantReturned}
						grantSuccess={grantSuccess}
					/>
				</div>
			</div>
		</>
	);
};

export default GranteeScreen;
