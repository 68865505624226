// import "bootstrap/dist/css/bootstrap.min.css";
import { useRef } from "react";

import { Navbar, Nav } from "react-bootstrap";

import Hero from "../Sections/Hero";
import HowWorks from "../Sections/HowWorks";

const NavigationBar = ({ onClick = () => {} }) => {
	return (
		<>
			<Navbar bg="dark" variant="dark">
				<Navbar.Brand href="#home">
					<img
						alt=""
						src="/img/logo.png"
						width="auto"
						height="35"
						className="d-inline-block align-top"
					/>
				</Navbar.Brand>
				<Nav.Link href="#home" onClick={onClick}>
					How it Works
				</Nav.Link>
				<Nav.Link href="#home">Benefits</Nav.Link>
				<Nav.Link href="#home">Reviews</Nav.Link>
				<Nav.Link href="#home">Support</Nav.Link>
				<Navbar.Toggle />
				<Navbar.Collapse className="justify-content-end">
					<Navbar.Text style={{ marginRight: 15 }}>
						Available on:{" "}
					</Navbar.Text>
					<Nav.Link href="#ios">
						<div className="store-button">
							<i className="mdi mdi-apple" aria-hidden="true"></i>{" "}
							iOS
						</div>
					</Nav.Link>
					<Nav.Link href="#ios">
						<div className="store-button">
							<i
								className="mdi mdi-android"
								aria-hidden="true"
							></i>{" "}
							Android
						</div>
					</Nav.Link>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

const Footer = () => {
	return (
		<Navbar bg="dark" variant="dark">
			<Navbar.Brand href="#home">
				<img
					alt=""
					src="/img/logo.png"
					width="auto"
					height="35"
					className="d-inline-block align-top"
				/>
			</Navbar.Brand>
			<Navbar.Collapse className="justify-content-end">
				<Navbar.Text style={{ marginRight: 15 }}>
					Available on:{" "}
				</Navbar.Text>
				<Nav.Link href="#ios">
					<div className="store-button">
						<i className="mdi mdi-apple" aria-hidden="true"></i> iOS
					</div>
				</Nav.Link>
				<Nav.Link href="#ios">
					<div className="store-button">
						<i className="mdi mdi-android" aria-hidden="true"></i>{" "}
						Android
					</div>
				</Nav.Link>
			</Navbar.Collapse>
		</Navbar>
	);
};

function Home() {
	const workRef = useRef(null);

	return (
		<div>
			<div
				className={"main-wrapper"}
				style={{
					padding: 20,
					flexDirection: "column",
					flex: 1,
					height: "calc(100vh - 80px)",
				}}
			>
				<div
					style={{
						fontSize: "3rem",
						textAlign: "center",
						fontWeight: "500",
					}}
				>
					Welcome to Grant
				</div>
			</div>
			<NavigationBar />
		</div>
	);
}

export default Home;
