import { language } from "../../../Helpers";

import { isIOS, isAndroid } from "react-device-detect";

const NoTip = ({ reset = () => {} }) => {
	const locale = window.navigator.userLanguage || window.navigator.language;
	const sentences = language(locale);

	return (
		<>
			<h4 className={"title"}>{sentences.np_tip.cta()}</h4>
			<div className={"center"}>
				{isAndroid && (
					<img
						onClick={() => {}}
						style={{
							width: "70%",
							height: "auto",
							marginTop: 10,
							marginBottom: 10,
						}}
						src={"/assets/icons/google-play-badge.png"}
						alt={"Google Play"}
					/>
				)}
				{isIOS && (
					<img
						onClick={() => {}}
						style={{
							width: "70%",
							height: "auto",
							marginTop: 10,
							marginBottom: 10,
						}}
						src={"/assets/icons/app-store-badge.png"}
						alt={"App Store"}
					/>
				)}
			</div>
			<div style={{ marginBottom: -10, textAlign: "center" }}>
				{/* <small>
          Send Grants faster, track your tips and many other benefits
        </small> */}
			</div>
			<button onClick={() => reset()} className={"button link"}>
				<span>{sentences.np_tip.link()}</span>
			</button>
		</>
	);
};

export default NoTip;
