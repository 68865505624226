import { useContext, useState, useEffect } from "react";
import { language, prices } from "../../../Helpers";

import AppContext from "../context";

const Tipping = ({ recordTip = () => {}, sendingTip = false }) => {
	const { Grantee } = useContext(AppContext);
	const [tip, setTip] = useState(null);
	const locale = window.navigator.userLanguage || window.navigator.language;
	const sentences = language(locale);

	useEffect(() => {
		setTimeout(() => {
			document
				.getElementsByClassName("price-container")[2]
				.scrollIntoView({
					behaviour: "smooth",
					block: "center",
					inline: "center",
				});
			setTip(2);
		}, 200);
	}, []);

	return (
		<>
			<h4 className={"title"}>{sentences.tip.title()}</h4>
			<div className={"tip-container"}>
				<div className={"empty-price-area"} />
				{prices.map((m, index) => {
					if (index === 0 && Grantee.profile.location !== "France") {
						return <></>;
					}
					return (
						<div
							key={"tip-" + index}
							className={
								index === tip
									? "price-container selected " +
									  (sendingTip && "disabled")
									: "price-container " +
									  (sendingTip && "disabled")
							}
							onClick={() => {
								!sendingTip && setTip(index);
								document
									.getElementsByClassName("price-container")
									[index].scrollIntoView({
										behaviour: "smooth",
										block: "center",
										inline: "center",
									});
							}}
						>
							<p className={"price"}>{m.amount}&nbsp;</p>
							<p className={"currency"}>EUR</p>
						</div>
					);
				})}
				<div className={"empty-price-area"} />
			</div>
			<div style={{ marginBottom: 10, textAlign: "center" }}>
				<small>{"Bank and Credit Card fees may apply"}</small>
			</div>
			<button
				onClick={() => recordTip(prices[tip])}
				className={sendingTip ? "button disabled" : "button"}
			>
				<span>
					{sentences.tip.cta(
						Grantee.getName(),
						(tip !== null ? prices[tip].amount : 0) + " EUR"
					)}
				</span>
			</button>
			<button onClick={() => recordTip(false)} className={"button link"}>
				<span>{sentences.tip.link()}</span>
			</button>
		</>
	);
};

export default Tipping;
