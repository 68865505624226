import { useState, useEffect, useContext, useCallback } from "react";
// import Rating from "./Rating";
import Thumbing from "./Thumbing";
import Tipping from "./Tipping";
import Message from "./Message";
import Sending from "./Sending";
import NoTip from "./NoTip";
import Error from "./Error";

import AppContext from "../context";
import { Redirect } from "react-router-dom";

const States = {
	rating: 0,
	tipping: 1,
	sending: 2,
	message: 3,
	no_tip: 4,
	error: 5,
	redirect: -1,
};

const Grantor = ({
	setLoading,
	setFinished,
	grantReturned = null,
	grantSuccess = false,
}) => {
	const { Firebase, Grantee, Lemonway, Grant } = useContext(AppContext);
	const [state, setState] = useState(States.rating);

	// Utils
	const initGrant = async (grant) => {
		const payment = await Firebase.initPayment(Grantee, grant);
		console.log("Grat inited", payment);
		Lemonway.openWebkit(payment);
	};

	const validateGrant = useCallback(
		async (grantKey) => {
			await Firebase.validateGrant(grantKey);
		},
		[Firebase]
	);

	const recordNoTip = async () => {
		const pendingGrant = await Firebase.recordNoTip(Grantee);
		validateGrant(pendingGrant);
		setState(States.no_tip);
	};

	const recordRating = (rate) => {
		Grant.setGrant((g) => Object.assign({}, g, { rate }));
		setState(States.tipping);
	};

	const recordTip = (tip) => {
		setState(States.sending);
		if (!tip) {
			recordNoTip();
		} else {
			Grant.setGrant((g) =>
				Object.assign({}, g, { tip: tip.amount, fee: tip.fee })
			);
			initGrant({ tip: tip.amount, fee: tip.fee });
		}
	};

	useEffect(() => {
		switch (state) {
			case States.sending:
				setLoading?.(true);
				return;
			case States.message:
				setFinished?.(true);
				return;
			default:
				setLoading?.(false);
				return;
		}
	}, [setFinished, setLoading, state]);

	useEffect(() => {
		if (grantReturned && state !== States.message && Grant.grant) {
			console.log();
			if (["error", "cancel"].indexOf(grantSuccess) >= 0) {
				setState(States.error);
				// TODO: Delete Pending Grant
			} else {
				validateGrant(grantReturned);
				setState(States.message);
			}
		}
	}, [Grant.grant, grantReturned, grantSuccess, state, validateGrant]);

	switch (state) {
		case States.rating:
			return (
				<Thumbing
					Grant={Grant}
					recordRate={recordRating}
					finish={() => setState(States.no_tip)}
				/>
			);
		case States.tipping:
			return (
				<Tipping
					Grant={Grant}
					recordTip={recordTip}
					finish={() => setState(States.no_tip)}
				/>
			);
		case States.sending:
			return <Sending />;
		case States.message:
			return <Message />;
		case States.no_tip:
			return <NoTip reset={() => setState(States.rating)} />;
		case States.error:
			return (
				<Error
					reset={() => {
						// TODO: Fix this
						setState(States.redirect);
						// window.href = `/devgrantee/${Grantee.uid}`;
					}}
				/>
			);
		case States.redirect:
			return <Redirect to={`/devgrantee/${Grantee.uid}`} />;
		default:
			return <></>;
	}
};

export default Grantor;
