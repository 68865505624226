import { useContext, useState, useEffect, useRef } from "react";
import { language } from "../../../Helpers";

import AppContext from "../context";

const thumbs = {
  mute: "/assets/thumbs/thumb-mute.png",
  white: "/assets/thumbs/thumb-white.png",
  active: "/assets/thumbs/thumb-active.png",
};

const Rating = ({ recordRate = () => {}, finish = () => {} }) => {
  // const { Grantee } = useContext(AppContext);
  const locale = window.navigator.userLanguage || window.navigator.language;
  const sentences = language(locale);

  const thumbRef = useRef(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // const onClick = () => {
    //   setIsActive(true);
    // };
    const onMouseDown = () => {
      setIsClicked(true);
    };
    const onMouseUp = () => {
      setIsActive(true);
    };
    const thumbElem = thumbRef.current;

    thumbElem.addEventListener("mousedown", onMouseDown);
    thumbElem.addEventListener("mouseup", onMouseUp);

    thumbElem.addEventListener("touchstart", onMouseDown);
    thumbElem.addEventListener("touchend", onMouseUp);

    return () => {
      thumbElem.removeEventListener("mousedown", onMouseDown);
      thumbElem.removeEventListener("mouseup", onMouseUp);

      thumbElem.removeEventListener("touchstart", onMouseDown);
      thumbElem.removeEventListener("touchend", onMouseUp);
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        recordRate(1);
      }, 500);
    }
  }, [isActive, recordRate]);

  return (
    <>
      <h4 className={"title"}>{sentences.rate.title()}</h4>
      <div className="thumb-container">
        <img
          ref={thumbRef}
          src={
            isActive ? thumbs.active : isClicked ? thumbs.white : thumbs.mute
          }
          className={"thumbs mute"}
          alt="thumbs-up"
        />
      </div>
      {/* <button onClick={() => recordRate(1)} className={"button"}>
        <span>{sentences.rate.cta(Grantee.getName())}</span>
      </button>
      <button onClick={() => finish()} className={"button link"}>
        <span>{sentences.rate.link()}</span>
      </button> */}
    </>
  );
};

export default Rating;
