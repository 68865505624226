import { Row, Col } from "react-bootstrap";

function Hero() {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row>
        <Col xs={6} className="hero-part">
          <img
            className="img img-fluid"
            src="/img/grant-banner.png"
            alt="alt"
          />
        </Col>
        <Col xs={6} className="hero-part">
          <img
            alt=""
            src="/img/logo.png"
            style={{
              width: 280,
              marginLeft: -20,
            }}
          />
          <div className="hero-subtitle">
            Reward a Service with a{" "}
            <span className="primary-highlight">QR Code</span>
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div className="store-button big mr-3">
              <i className="mdi mdi-apple" aria-hidden="true"></i> iOS
            </div>
            <div className="store-button big">
              <i className="mdi mdi-android" aria-hidden="true"></i> Android
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Hero;
