import config from "./config";

class LemonwayWrapper {
	constructor(env = "prod") {
		this.env = env;
	}

	setEnv(env) {
		this.env = env;
	}

	getWebkitUrl() {
		return config.webkit[this.env];
	}
}

class Lemonway extends LemonwayWrapper {
	/**
	 * Generate webkit url by combining it with webkitToken;
	 * @param {*} result
	 * @returns
	 */
	generateWebkitUrl(result) {
		const webkitToken = result.webKitToken;
		return this.getWebkitUrl() + webkitToken;
	}

	/**
	 * Open webkit to make the payment
	 * @param {*} result Result from FB functions
	 * MUST CONTAIN webKitToken
	 */
	openWebkit(result) {
		window.location.href = this.generateWebkitUrl(result);
	}
}

export default Lemonway;
