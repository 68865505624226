import Lottie from "react-lottie";
import animationData from "../../../Assets/animation/preparing.json";

import { language } from "../../../Helpers";

const Sending = () => {
  const locale = window.navigator.userLanguage || window.navigator.language;
  const sentences = language(locale);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className={"center"}>
        <Lottie
          options={defaultOptions}
          height={window.innerWidth * 0.45}
          width={window.innerWidth * 0.45}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        <p>{sentences.sending.sentence()}</p>
      </div>
    </>
  );
};

export default Sending;
