const sentences = {
  rate: {
    sentence: () => "It was a pleasure to be at your service today ツ",
    title: () => "Did you enjoy it ?",
    cta: (name) => `Yes, thank you ${name}`,
    link: () => `I don’t want to say thank you`,
  },
  tip: {
    sentence: () => "You are welcome. I hope to see you soon ツ",
    title: () => "Adding a tip would be even better!",
    cta: (name, amount) => `Send ${name} ${amount}`,
    link: () => `I don’t want to add a tip`,
  },
  sending: {
    sentence: () => "Preparing your Grant ツ",
  },
  after_payment: {
    sentence: () => "That's very kind. I really appreciate it. See you soon ツ",
    title: () => "Thank you",
    cta: () => "Download the app",
    link: (name, amount) => `${name} has received ${amount} ツ`,
  },
  np_tip: {
    sentence: () => "See you soon ツ",
    title: () => "",
    cta: () => "Download the app",
    link: () => "I changed my mind",
  },
  error: {
    sentence: () => "ｼ",
    title: () => "Oops, something went wrong",
    cta: () => "Try again",
    link: (name, amount) =>
      `It appears your payment was not successful, please try again or check your payment method`,
  },
};

export default sentences;
