import Lottie from "react-lottie";
import animationData from "../../Assets/animation/searching.json";

const NotFound = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	return (
		<div className="grantee-wrapper">
			<div>
				<Lottie options={defaultOptions} />
			</div>
			<div style={{ padding: 25 }}>
				<h4 style={{ textAlign: "center" }}>
					Grantee
					<br />
					not found
				</h4>
				<br />
				<p style={{ textAlign: "center" }}>
					QR code scan did not find any Grantee! Please scan a valid
					Grant QR Code
				</p>
			</div>
		</div>
	);
};

export default NotFound;
