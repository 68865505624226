import "./style.css";

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <div className="linear-progress-material">
        <div className="bar bar1" />
        <div className="bar bar2" />
      </div>
    </div>
  );
};

export default Loader;
