import config from "./config";

const DEBUG = true;

class GranteeWrapper {
  constructor(grantee, fbService = null) {
    this.uid = grantee.uid;
    this.profile = grantee;
    this.profile_image = null;

    DEBUG && console.log("Grantee loaded", this.profile);
    this._initColorScheme();
    this._initMessage();

    this.firebase = fbService;
  }

  _initColorScheme() {
    this.colors = config.colors[this.profile.colorScheme];
  }

  _initMessage() {
    this.message = config.greetings[this.profile.message];
  }

  getBackground() {
    return this.colors.color;
  }

  getName() {
    return this.profile.name;
  }

  getMessage() {
    return {
      message: this.message,
      color: this.colors.overlay,
    };
  }

  setProfileImage(profile_image) {
    this.profile_image = profile_image;
  }
}

class Grantee extends GranteeWrapper {}

export default Grantee;
