import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";

import Firebase from "../../Services/Firebase";
import GranteeService from "../../Services/Grantee";

import AppContext from "./context";

import "../../Assets/css/grantee.css";
import confeti from "../../Assets/animation/confeti.json";
import QRLoader from "../../Components/QRLoader";
import NotFound from "./NotFound";
import GranteeScreen from "./GranteeScreen";
import Loader from "../../Components/Loader";
import Lemonway from "../../Services/Lemonway";

let F = new Firebase();
let L = new Lemonway();

const APPSTATES = {
	loading: 0,
	not_found: -1,
	ready: 1,
};

const G = ({ dev = false }) => {
	const [myUser, setMyUser] = useState(null);
	const [granteeService, setGranteeService] = useState(null);
	const [grant, setGrant] = useState(null);
	const [loading, setLoading] = useState(false);
	const [finished, setFinished] = useState(false);
	const [preload, setPreload] = useState(null);

	const [state, setState] = useState(APPSTATES.loading);
	const { userCode, pending_grant, success } = useParams();

	useEffect(() => {
		// console.log(userCode, pending_grant, success);
		F.setEnv(dev ? "dev" : "prod");
		L.setEnv(dev ? "dev" : "prod");
		F.authUser((user) => {
			setMyUser(user);
		});
	}, [dev]);

	useEffect(() => {
		const loadGrantee = async () => {
			const g = await F.loadGrantee(userCode);
			if (g) {
				const gService = new GranteeService(g);
				const profileImage = await F.dowloadProfileImage(
					g.profile_image
				);
				gService.setProfileImage(profileImage);
				setGranteeService(gService);
				if (success && pending_grant) {
					const pendingGrant = await F.loadPendingGrant(
						pending_grant
					);
					setGrant(pendingGrant);
				} else {
					F.trackScan(gService.uid);
					F.initPreload(gService).then((preloadUrl) => {
						const webkit = L.generateWebkitUrl(preloadUrl);
						setPreload(webkit);
					});
				}
				setState(APPSTATES.ready);
			} else {
				setState(APPSTATES.not_found);
			}
		};

		if (myUser && !granteeService) {
			loadGrantee();
		}
	}, [myUser, userCode, success, pending_grant, granteeService]);

	return (
		<AppContext.Provider
			value={{
				Grantee: granteeService,
				Firebase: F,
				Lemonway: L,
				Grant: { grant, setGrant },
			}}
		>
			{loading && <Loader />}
			{finished && (
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
					}}
				>
					<Lottie
						options={{
							loop: false,
							autoplay: true,
							animationData: confeti,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice",
							},
						}}
						height={window.innerHeight}
						width={window.innerWidth}
						onAnimationFinish={() => setFinished(false)}
					/>
				</div>
			)}

			<div
				className={"main-wrapper"}
				style={{
					background: granteeService?.getBackground() || "#030b04",
					height: window.innerHeight,
				}}
			>
				{state === APPSTATES.loading && (
					<div className={"grantee-wrapper"}>
						<QRLoader code={userCode} />
					</div>
				)}
				{state === APPSTATES.not_found && <NotFound />}
				{state === APPSTATES.ready && (
					<GranteeScreen
						setFinished={setFinished}
						setLoading={setLoading}
						grantReturned={
							success && pending_grant ? pending_grant : null
						}
						grantSuccess={success}
					/>
				)}
			</div>
			{preload && (
				<div
					style={{
						position: "fixed",
						right: -2 * window.innerWidth,
						bottom: -2 * window.innerHeight,
					}}
				>
					<iframe
						src={preload}
						height="10"
						width="10"
						title="Lemonway webkit preload"
					></iframe>
				</div>
			)}
		</AppContext.Provider>
	);
};

export default G;
