// import QrReader from "react-qr-scanner";
import { QrReader } from "react-qr-reader";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";

const Scanner = () => {
  return (
    <>
      {/* <QrReader
        style={{
          height: "100vh",
          width: "100vw",
        }}
        onError={(error) => {
          alert(error);
        }}
        onScan={(data) => {
          if (!!data) {
            console.log(data);
          }
        }}
        chooseDeviceId={(e) => alert(e)}
        facingMode={"front"}
      /> */}
      <QrReader
        onResult={(result, error) => {
          if (!!result) {
            alert(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        style={{ width: "100%" }}
      />
      <AddToHomeScreen />
    </>
  );
};

export default Scanner;
