import en from "./locales/en";
import fr from "./locales/fr";

export const prices = [
	{
		amount: 1,
		// fee: 0.25,
		fee: 1 * 0.18,
		is_default: false,
	},
	{
		amount: 2,
		// fee: 0.34,
		fee: 2 * 0.18,
		is_default: false,
	},
	{
		amount: 5,
		// fee: 0.51,
		fee: 5 * 0.18,
		is_default: true,
	},
	{
		amount: 10,
		// fee: 0.74,
		fee: 10 * 0.18,
		is_default: false,
	},
	{
		amount: 15,
		// fee: 0.85,
		fee: 15 * 0.18,
		is_default: false,
	},
	{
		amount: 20,
		// fee: 1.05,
		fee: 20 * 0.18,
		is_default: false,
	},
	{
		amount: 30,
		// fee: 1.52,
		fee: 30 * 0.18,
		is_default: false,
	},
	{
		amount: 40,
		// fee: 1.91,
		fee: 40 * 0.18,
		is_default: false,
	},
	{
		amount: 50,
		// fee: 2.48,
		fee: 50 * 0.18,
		is_default: false,
	},
];

export const language = (locale = "en") => {
	if (locale.includes("en") || locale.includes("EN")) return en;
	else if (locale.includes("fr") || locale.includes("FR")) return fr;
	else return en;
};
