const sentences = {
  rate: {
    sentence: () => "Ça a été un plaisir d’être à votre service aujourd’hui ツ",
    title: () => "Ça vous a plu ?",
    cta: (name) => `Oui, merci ${name}`,
    link: () => `Je ne veux pas dire merci`,
  },
  tip: {
    sentence: () => "J'en suis ravi(e). J'espère vous voir bientôt ツ\n",
    title: () => "Ajouter un pourboire, c’est encore mieux !",
    cta: (name, amount) => `Envoyez à ${name} ${amount}`,
    link: () => `Je ne veux pas ajouter de pourboire`,
  },
  sending: {
    sentence: () => "Préparation de votre Grant ツ",
  },
  after_payment: {
    sentence: () => "C’est très gentil. J’apprécie vraiment. À très bientôt ツ",
    title: () => "Merci",
    cta: () => "Téléchargez l’application",
    link: (name, amount) => `${name} a reçu ${amount} ツ`,
  },
  np_tip: {
    sentence: () => "À bientôt ツ",
    title: () => "",
    cta: () => "Téléchargez l’application",
    link: () => "J'ai changé d'avis",
  },
  error: {
    sentence: () => "ｼ",
    title: () => "Oops, something went wrong",
    cta: () => "Try again",
    link: (name, amount) =>
      `It appears your payment was not successful, please try again or check your payment method`,
  },
};

export default sentences;
