const config = {
	apiKey: "AIzaSyBF-cXaCx3aw15S-ecJEncAGS49nkN93Ps",
	authDomain: "grant-app-75119.web.app",
	// databaseURL: "https://grant-app-75119.firebaseio.com",
	databaseURL:
		"https://grant-app-live-1-0-0.europe-west1.firebasedatabase.app/",
	projectId: "grant-app-75119",
	storageBucket: "grant-app-live-1-0-0",
	messagingSenderId: "18112193895",
	appId: "1:18112193895:web:94b07b07b1bd1acef77bc2",
	measurementId: "G-4CQFETHDXB",
};

export default config;
