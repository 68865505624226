// import "bootstrap/dist/css/bootstrap.min.css";
import "./App/Assets/css/bootstrap.min.css";
import "./App.css";

import "@mdi/font/css/materialdesignicons.min.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./App/Screens/Home";
import Grantee from "./App/Screens/G";
import { OpenAPIProvider } from "react-openapi-client";
import Scanner from "./App/Screens/Scanner";
// import Grantor from "./App/Screens/Grantor";

function App() {
  return (
    <OpenAPIProvider definition="/lemonway.yaml">
      <Router>
        <Switch>
          <Route path="/grantee/:userCode/:pending_grant?/:success?">
            <Grantee />
          </Route>
          <Route path="/devgrantee/:userCode/:pending_grant?/:success?">
            <Grantee dev={true} />
          </Route>
          <Route path="/scan">
            <Scanner />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </OpenAPIProvider>
  );
}

export default App;
