import { useContext, useEffect } from "react";
import { language } from "../../../Helpers";

import { isIOS, isAndroid } from "react-device-detect";
import AppContext from "../context";

const Message = () => {
	const { Grantee, Grant } = useContext(AppContext);
	const locale = window.navigator.userLanguage || window.navigator.language;
	const sentences = language(locale);

	useEffect(() => {
		console.log(Grant.grant);
	}, [Grant.grant]);

	return (
		<>
			<h4 className={"title"}>{sentences.after_payment.title()}</h4>
			<div style={{ marginTop: -10, textAlign: "center" }}>
				<small>
					{sentences.after_payment.link(
						Grantee.getName(),
						(Grant.grant?.tip.toFixed(2) || 0) + " EUR"
					)}
				</small>
			</div>
			<div className={"center"}>
				{isAndroid && (
					<img
						onClick={() => {}}
						style={{
							width: "70%",
							height: "auto",
							marginTop: 10,
							marginBottom: 10,
						}}
						src={"/assets/icons/google-play-badge.png"}
						alt={"Google Play"}
					/>
				)}
				{isIOS && (
					<img
						onClick={() => {}}
						style={{
							width: "70%",
							height: "auto",
							marginTop: 10,
							marginBottom: 10,
						}}
						src={"/assets/icons/app-store-badge.png"}
						alt={"App Store"}
					/>
				)}
			</div>
			<div style={{ textAlign: "center" }}>
				{/* <small>
          Send Grants faster, track your tips and many other benefits
        </small> */}
			</div>
		</>
	);
};

export default Message;
