import { language } from "../../../Helpers";

const Error = ({ reset = () => {} }) => {
  const locale = window.navigator.userLanguage || window.navigator.language;
  const sentences = language(locale);

  return (
    <>
      <h4 className={"title"}>{sentences.error.title()}</h4>
      <div style={{ textAlign: "center" }}>
        <small>{sentences.error.link()}</small>
      </div>
      <button onClick={() => reset()} className={"button link"}>
        <span>{sentences.error.cta()}</span>
      </button>
      <div style={{ textAlign: "center" }}>
        <small>{""}</small>
      </div>
    </>
  );
};

export default Error;
