import "./style.css";
import QRCode from "react-qr-code";

const Loader = (code = "") => {
	return (
		<div className="qr-wrapper container">
			<div className={"corner-wrapper"}>
				<div className={"message-wrapper"}>
					<div className={"qr-container"}>
						<QRCode
							value={"https://t.joingrant.com/grantee/" + code}
							size={
								window.innerWidth > 800
									? 475
									: window.innerWidth * 0.63
							}
						/>
						<div className={"overlay"} />
					</div>
				</div>
				<div className={"corner tl"} />
				<div className={"corner bl"} />
				<div className={"corner tr"} />
				<div className={"corner br"} />
				<div className={"scan-line"} />
			</div>
		</div>
	);
};

export default Loader;
