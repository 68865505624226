import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";

import config from "./config";

const DEBUG = true;

class FirebaseWrapper {
	constructor(env = "prod") {
		this.env = env;
		this._initFirebase();
		DEBUG && console.log("Firebase successfully initialized");
	}

	_initFirebase() {
		if (firebase.apps.length) {
			firebase.app(); // if already initialized, use that one
		} else {
			firebase.initializeApp(config.prod);
			firebase.initializeApp(config.dev, "dev");
			firebase.functions();
		}
	}

	_getDevApp() {
		if (firebase.apps.length === 0)
			throw new Error("Firebase apps not initialized");

		return firebase.apps.find((c) => c.container.name === "dev");
	}

	_getProdApp() {
		if (firebase.apps.length === 0)
			throw new Error("Firebase apps not initialized");

		return firebase.apps.find((c) => c.container.name !== "dev");
	}

	_getApp() {
		if (this.env === "dev") return this._getDevApp();
		else return this._getProdApp();
	}

	_getStorage() {
		if (this.env === "dev") return firebase.storage(this._getDevApp());
		else return firebase.storage();
	}

	_getAuth() {
		if (this.env === "dev") return firebase.auth(this._getDevApp());
		else return firebase.auth();
	}

	_getDb() {
		if (this.env === "dev") return firebase.database(this._getDevApp());
		else return firebase.database();
	}

	_getFn() {
		return this._getApp().functions("europe-west3");
	}

	async signIn() {
		return this._getAuth().signInAnonymously();
	}

	setEnv(env) {
		this.env = env;
	}
}

export default FirebaseWrapper;
